import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { commonStyles } from 'src/styles';

export const NAV_Z_INDEX = 1;

/**
 * Create the styles for the high-level `UserPortal` component.
 *
 * Because the `UserPortal` component is not functional the normal `makeStyles` higher order component is
 * useless. So this copies the existing `commonStyles` and adds our specific styling to that.
 *
 * @param theme The theme to use
 */
export const styles = (theme: Theme) => ({
    ...commonStyles(theme),

    profileComponent: {
        // Without this Safari generates a gap to the left of the Profile
        // button when the _Popper_ component is opened.
        width: 'min-content',
    },
    appsMenuIcon: {
        height: '100%',
    },
    buttonWrapper: {
        maxWidth: 'calc(100% - 145px)',
    },
    nav: {
        zIndex: NAV_Z_INDEX,
    },
});

export const useStyles = makeStyles()(styles);
